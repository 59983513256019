<template>
    <div class="relative">
        <svg-importer icon-name="icons/calendar" key="date-picker_calendar" class="absolute z-10 mt-2 ml-3 pointer-events-none" />
        <input
            :value="formattedDate"
            @click="showPicker = true"
            class="date-range-picker"
            ref="dateRangePicker"
            type="text"
            :class="{'is-invalid' : error}"
            :disabled="disabled"
            :placeholder="placeholder"
        />

        <button v-if="clearable && formattedDate" class="absolute text-lg top-2 right-3 cursor-pointer" @click="clear">
            <svg-importer icon-name="icons/close" />
        </button>

        <transition name="slide">
            <div
                class="absolute z-20 w-full w__min-300 mt-1 border border-gray-400 rounded card"
                v-closable="{
                    exclude: ['dateRangePicker'],
                    handler: 'onClose'
                }"
                v-if="showPicker"
            >
                <calendar
                    :class="{'no-branding': ! branding}"
                    :first-day-of-week="1"
                    :sync-date.sync="date"
                    @change="onChange"
                    class="calendar"
                    :disable-days-before-today="disablePast"
                    :days-disabled-start="disabledStart"
                    lang="en"
                    month-year-format="MMMM YYYY"
                ></calendar>
            </div>
        </transition>

        <transition name="fade">
            <span v-if="error" class="w-full text-red-500 help-block">
                <span>{{ error[0] }}</span>
            </span>
        </transition>
    </div>
</template>

<script>
import moment from "moment";
import { Calendar } from "vue-date-range";

export default {
    name: "DatePicker",

    props: {
        value: {},
        disablePast: {
            type: Boolean,
            required: false,
            default: true
        },
        branding: {
            required: false,
            default: true,
            type: Boolean
        },
        disabled: {
            required: false,
            default: false,
            type: Boolean
        },
        formatOnEmit: {
            required: false,
            default: false,
        },
        error: {
            required: false,
            default: false,
        },
        clearable: {
            default: false,
        },
        disabledStart: null,
        placeholder: String,
    },

    components: {
        Calendar
    },

    data: () => ({
        showPicker: false,
        date: moment(),
        time: null
    }),

    created() {
        if (this.value) {
            if (! (this.value instanceof moment)) {
                this.date = moment(this.value);
            } else {
                this.date = this.value;
            }

            return;
        }

        this.date = null;
    },

    computed: {
        formattedDate() {
            if (!this.date) {
                return '';
            }
            
            return (
                moment(this.date).format("LL")
            );
        },
    },

    methods: {
        onChange(date) {
            this.date = date

            if (date instanceof moment && this.formatOnEmit) {
                date = date.format('Y-MM-DD');
            }

            this.showPicker = false;
            this.$emit('input', date)
            this.$emit('change', date)
        },
        onClose() {
            this.showPicker = false
        },
        clear() {
            this.date = null;
            this.time = null;

            this.$emit('input', null)
            this.$emit('change', null)
            this.$emit('close')
        }
    },

    watch: {
        value(value) {
            if (value && this.date != value) {
                this.date = moment(value);
                return;
            }

            if (this.date != value) {
                this.date = value;
            }
        }
    }
};
</script>

<style lang="scss">
.date-range-picker {
    @apply pl-12 #{!important};
}

.date-range-picker {
    height: 32px;
}

.v-date-calendar {
    .v-date-month-year {
        @apply border-b text-lg py-4 px-2 mb-4;
    
        flex: 0;
    }
}
</style>

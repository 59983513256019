<template>
  <div class="dropdown-block">
    <button v-if="!titleHidden" @click="show = !show">
      <svg-importer icon-name="icons/arrow" class="ml-4 mr-4" :class="{ 'rotate-180': show }" />
      <h4 v-text="title" />
    </button>
    <transition name="slide">
      <div v-show="show || !displayDropdown">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    titleHidden: Boolean,
    displayDropdown: {
      type: Boolean,
      default: true,
    },
    defaultShow: Boolean,
  },
  data() {
    return {
      show: this.defaultShow,
    };
  },
}
</script>

<style lang="scss" scoped>
.dropdown-block {
  button {
    display: flex;
    align-items: center;
    height: 40px;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
  }
}
</style>
<template>
    <div>
        <card v-if="currentStepKey === 'address' && !isTemplate" class="mb-4" bodyClass="overflow-visible-important">
            <div class="flex flex__column">
                <modal-field v-if="$route.name === 'transport.priceleadtime.form'" label="Carrier service options (compare prices and lead time)" required class="mb-2">
                    <div class="flex">
                        <label class="flex flex__align-center mr-3">
                            <input v-model="shortFlowRadio" type="radio" :value="flowTypeMap.quote" @change="updateFlow" class="form-radio mr-2"/>
                            <div for="sender" class="f-size-12">Send price request</div>
                        </label>
                        <label class="flex flex__align-center mr-3">
                            <input v-model="shortFlowRadio" type="radio" :value="flowTypeMap.short" @change="updateFlow" class="form-radio mr-2"/>
                            <div for="receiver" class="f-size-12">Book a specific service</div>
                        </label>
                    </div>
                </modal-field>

                <modal-field label="Freight payer" required class="mb-2">
                    <div class="flex">
                        <label v-for="payerType in payerTypes" :key="payerType.id" class="flex flex__align-center mr-3">
                            <input
                                v-model="deliveryTermsData.payer"
                                :disabled="getIsLockedField('payer')"
                                type="radio"
                                :value="payerType.id"
                                class="form-radio mr-2"
                            />
                            <div :for="payerType.id" class="f-size-12">{{ payerType.name }}</div>
                        </label>
                    </div>
                </modal-field>

                <div class="flex">
                    <modal-field
                        :label="'Transport date'"
                        required
                        :disabled="currentStep == 3"
                        :class="{ empty: getIsEmptyField(addressData['pickup'].pickup_date) }"
                    >
                        <date-picker
                            v-model="addressData['pickup'].pickup_date"
                            :disable-past="true"
                            :format-on-emit="true"
                            :disabled="currentStepKey === 'create_booking' && Boolean(addressData['pickup'].pickup_date)"
                        />
                    </modal-field>

                    <modal-field
                        v-for="datetime in [addressData.pickup.pickupTimeEarliest, addressData.pickup.pickupTimeLatest]"
                        :key="`datetime_${datetime.label}`"
                        :label="`${datetime.label} pickup`"
                        class="pl-4"
                    >
                        <date-time-picker
                            v-model="datetime.value"
                            :disabled-time="getDisabledTime(datetime.key)"
                            type="time"
                            placeholder="Select time"
                            :show-second="false"
                            :minute-step="15"
                            format="HH:mm"
                            @change="updatePickupTime(datetime.key, $event)"
                        />
                    </modal-field>
                </div>
            </div>
        </card>
        
        <dropdown
            :title="addressDropdownTitleText"
            :display-dropdown="isTemplate"
            :title-hidden="!isTemplate"
            :default-show="true"
        >
            <div class="flex flex-wrap w-full">
                <div
                    v-for="(section, index) in ['consignor', 'consignee', 'pickup', 'delivery']"
                    :key="section"
                    :index="index % 2"
                    class="w-1/2 mb-6 flex flex__column"
                    :class="{ 'pr-4': (index + 1) % 2 }"
                >
                    <card
                        class="w-full flex__grow"
                        :title="section"
                        v-if="data[section].active"
                        body-class="flex-wrap overflow-visible"
                    >
                        <div v-if="currentStep === 0" slot="tools">
                            <button v-if="isFormAccessable" :key="`form-reset-${section}`" @click="reset(section)">
                                <svg-importer icon-name="icons/reset" width="20px" />
                            </button>
                        </div>

                        <modal-field
                            :label="'Company'"
                            required
                            class="mb-4 w-full"
                            :class="{ empty: getIsEmptyField(data[section].name) }"
                            :bodyClass="'flex flex__align-center'"
                            :compact="isTemplate"
                        >
                            <div class="relative cinput-autocomplete w-full">
                                <autocomplete
                                    v-model="data[section].name"
                                    :endpoint="(val) => `${$apiUrl.addressBook.base}/search?name=${val}&type=${getCompanyNameFilters(section)}`"
                                    :disabled="disabledForm(fillData[section].name) || getIsLockedField(`${section}_address`)"
                                    :body-class="[{'is-invalid' : hasWarning(section, 'name')}]"
                                    label="complete_address"
                                    :placeholder="isTemplate ? 'Company' : ''"
                                    @click="selectAutoFill($event, section)"
                                >
                                    <template v-slot="slotProps">
                                        {{ slotProps.data.name }}
                                        <span class="mr-2 text-gray-600">
                                            {{ getCompleteAddress(slotProps.data) }}
                                        </span>
                                    </template>
                                </autocomplete>
                            </div>
                            <div v-if="!disabledForm(fillData[section].name)" class="relative">
                                <app-spinner v-if="!isCreateBooking && isCitiesLoading" :size="17" class="mt-1 ml-4" />
                                <button
                                    v-if="!isCreateBooking && !isCitiesLoading"
                                    type="button"
                                    class="address-book-button flex self-center ml-2 text-2xl cursor-pointer"
                                    :disabled="Boolean(lockedFields[`${section}_address`])"
                                    @click="openAddressModal(section)"
                                >
                                    <svg-importer icon-name="icons/home" :key="`add-to-ad_${section}`" />
                                </button>
                            </div>
                        </modal-field>
        
                        <modal-field
                            :label="'Address line 1'"
                            :required="isCreateBooking || isShortFlow"
                            class="mb-4 w-full"
                            :class="{ empty: getIsEmptyField(data[section].address) }"
                            :compact="isTemplate"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="data[section].address"
                                autocomplete="none"
                                :class="{'is-invalid' : hasWarning(section, 'address')}"
                                :disabled="disabledForm(fillData[section].address) || getIsLockedField(`${section}_address`)"
                                :placeholder="isTemplate ? 'Address line 1' : ''"
                                @input="data[section].address = data[section].address.slice(0, 35)"
                            />
        
                            <transition name="fade">
                                <div v-if="data[section].address && data[section].address.length > 30" v-text="textLimitMessage" class="mt-2 color-grey" />
                            </transition>
                        </modal-field>
        
                        <modal-field
                            :label="'Address line 2'"
                            :class="'mb-4 w-full'"
                            :compact="isTemplate"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="data[section].address2"
                                autocomplete="none"
                                :class="{'is-invalid' : hasWarning(section, 'address')}"
                                :disabled="disabledForm(fillData[section].address2) || getIsLockedField(`${section}_address`)"
                                @input="data[section].address2 = data[section].address2.slice(0, 35)"
                                :placeholder="isTemplate ? 'Address line 2' : ''"
                            />
        
                            <transition name="fade">
                                <div v-if="data[section].address2 && data[section].address2.length > 30" v-text="textLimitMessage" class="mt-2 color-grey" />
                            </transition>
                        </modal-field>
        
                        <modal-field
                            :label="'Zip code'"
                            required
                            class="mb-4 pr-4"
                            :class="[isTemplate ? 'w-1/2' : 'w-1/3', { empty: getIsEmptyField(data[section].zip) }]"
                            :compact="isTemplate"
                        >
                            <t-input
                                type="text"
                                class="mt-1"
                                v-model="data[section].zip"
                                autocomplete="none"
                                :error="hasWarning(section, 'zip')"
                                :disabled="disabledForm(fillData[section].zip) || getIsLockedField(`${section}_address`)"
                                :class="{'is-invalid' : hasWarning(section, 'zip')}"
                                :placeholder="isTemplate ? 'ZIP code' : ''"
                            />
                        </modal-field>
        
                        <modal-field
                            :label="'City'"
                            class="mb-4"
                            :class="[isTemplate ? 'w-1/2' : 'w-2/3', { empty: getIsEmptyField(data[section].city) }]"
                            :disabled="currentStep == 3"
                            :required="isCreateBooking || isShortFlow"
                            :compact="isTemplate"
                        >
                            <t-input
                                type="text"
                                class="mt-1"
                                v-model="data[section].city"
                                autocomplete="none"
                                :error="hasWarning(section, 'city')"
                                :disabled="disabledForm(fillData[section].city) || getIsLockedField(`${section}_address`)"
                                :class="{'is-invalid' : hasWarning(section, 'city')}"
                                :placeholder="isTemplate ? 'City' : ''"
                            />
                        </modal-field>
        
                        <modal-field
                            :label="'State'"
                            class="w-2/4 pr-4 mb-4"
                            :compact="isTemplate"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="data[section].state"
                                :disabled="getIsLockedField(`${section}_address`)"
                                autocomplete="none"
                                :placeholder="isTemplate ? 'State' : ''"
                            />
                        </modal-field>
        
                        <modal-field
                            :label="'Country'"
                            required
                            class="w-2/4 mb-4"
                            :class="{ empty: getIsEmptyField(data[section].country) }"
                            :disabled="currentStep == 3"
                            :compact="isTemplate"
                        >
                            <multiselect
                                v-model="data[section].country"
                                class="mt-1 disabled"
                                :class="{'is-invalid' : hasWarning(section,'country')}"
                                :options="countriesList[section].length ? countriesList[section] : countries"
                                track-by="value"
                                label="name"
                                :placeholder="isTemplate ? 'Select country' : ''"
                                :disabled="disabledForm(fillData[section].country) || getIsLockedField(`${section}_address`)"
                                @search-change="searchCountry($event, section)"
                            >
                            </multiselect>
        
                            <transition name="fade">
                                <span v-if="hasWarning(section,'country')" class="w-full text-red-500 help-block">
                                    <span>{{ hasWarning(section, 'country')[0] }}</span>
                                </span>
                            </transition>
                        </modal-field>
                        
                        <template v-if="!isPltFlow">
                            <modal-field
                                :label="'Contact person'"
                                :required="isShortFlow || currentStep == 3"
                                class="w-full mb-4"
                                :class="{ empty: getIsEmptyField(data[section].contact_person) }"
                                :bodyClass="'flex flex__align-center'"
                                :compact="isTemplate"
                            >
                                <div class="w-full">
                                    <autocomplete
                                        v-model="data[section].contact_person"
                                        :endpoint="contactSearchEndpoint"
                                        :params="{ organization_id: data[section].company_model.organization_id }"
                                        :disabled="getIsLockedField(`${section}_contact`)"
                                        required
                                        :body-class="['mt-1']"
                                        :placeholder="isTemplate ? 'Contact person' : ''"
                                        @click="selectContactAutoFill($event, section)"
                                    >
                                        <template v-slot="slotProps">
                                            <span v-if="slotProps.data.default" class="mr-2 text-gray-600">(default)</span>
                                            {{ slotProps.data.name }}
                                            <span class="mr-2 text-gray-600" v-text="slotProps.data.email" />
                                        </template>
                                    </autocomplete>
                                </div>
                                <span v-if="isFormAccessable && !getIsLockedField(`${section}_contact`)" class="flex self-center ml-2 text-2xl cursor-pointer" @click="openContactModal(section)">
                                    <svg-importer icon-name="icons/user" :key="`user-ub_${section}`" />
                                </span>
                            </modal-field>
                            <div class="flex w-full" :class="[isTemplate ? 'w-full' : 'flex__column']">
                                <modal-field
                                    :label="'Phone'"
                                    :required="isShortFlow || currentStep == 3"
                                    class="mb-4"
                                    :class="[isTemplate ? 'w-1/2 pr-4' : 'w-full', { empty: getIsEmptyField(data[section].phone) }]"
                                    :compact="isTemplate"
                                    :key="`input_${section}_phone`"
                                >
                                    <the-mask
                                        mask="+#############"
                                        class="mt-1"
                                        v-model="data[section].phone"
                                        :key="`${section}_phone_${componentCounterProp}`"
                                        :disabled="getIsLockedField(`${section}_contact`)"
                                        :placeholder="isTemplate ? 'Phone' : ''"
                                        required
                                    />
                                </modal-field>
                                <modal-field
                                    :label="'Email'"
                                    :required="isShortFlow || currentStep == 3"
                                    class="mb-4"
                                    :class="[isTemplate ? 'w-1/2' : 'w-full', { empty: getIsEmptyField(data[section].email) }]"
                                    :key="`autocomplete_${section}_email`"
                                    :compact="isTemplate"
                                >
                                    <autocomplete
                                        v-model="data[section].email"
                                        required
                                        :endpoint="contactSearchEndpoint"
                                        :params="{ organization_id: data[section].company_model.organization_id }"
                                        :is-valid="validateEmail(section)"
                                        :disabled="getIsLockedField(`${section}_contact`)"
                                        :placeholder="isTemplate ? 'Email' : ''"
                                        :class="getIsEmptyField(data[section].email)"
                                        label="email"
                                        @click="selectContactAutoFill($event, section)"
                                    >
                                        <template v-slot="slotProps">
                                            <span v-if="slotProps.data.default" class="mr-2 text-gray-600">(default)</span>
                                            {{ slotProps.data.name }}
                                            <span class="mr-2 text-gray-600" v-text="slotProps.data.email" />
                                        </template>
                                    </autocomplete>
                                </modal-field>
                            </div>
                        </template>

                        <button v-if="section === 'consignor'" class="btn-transparent" :disabled="!isFormAccessable" @click="copyConsignor">
                            Copy to Pickup
                        </button>
                        <button v-if="section === 'consignee'" class="btn-transparent" :disabled="!isFormAccessable" @click="copyConsignee">
                            Copy to Delivery
                        </button>

                    </card>
                </div>

                <transition name="fade">
                    <address-book
                        :show="showAddressTable"
                        v-model="owner"
                        :ref="addressBookRef"
                        :organizations="organizations"
                        :selected-company="selectedCompany[addressParent]"
                        :company-type-list="companyTypeDictionary"
                        :address-parent="addressParent"
                        :cities="cities"
                        @click="markCompany"
                        @dblclick="editAddress"
                        @dublicate="dublicateCompany"
                        @choose="chooseCompany"
                        @hide="closeAddressTableModal"
                        @open-create-address="showAddAddress(addressParent)"
                    />
                </transition>

                <modal
                    :show="showAddAddressBookModal"
                    :click-to-close="false"
                    @hide="hideAddAddressBookModal"
                    size="sm"
                    class="modal-add-address"
                >
                    <h2 slot="header">{{ addressBookTitles.title }}</h2>
                    <div slot="body" ref="addAddressModalBody" class="flex flex-wrap relative">
                        <spinner-block v-if="address.add.loading" />

                        <modal-field
                            label="Organization"
                            required
                            :class="'mb-4 w-full'"
                        >
                            <multiselect
                                v-model="address.add.organization"
                                class="mt-1"
                                :options="organizationsList"
                                :disabled="!isGodUser || loadingOrganizationsList"
                                track-by="id"
                                label="name"
                                :class="{'is-invalid' : hasWarning('add','organization')}"
                            >
                            </multiselect>
                        </modal-field>
                    <modal-field
                            :label="'Company name'"
                            required
                            :class="'mb-4 w-full'"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="address.add.name"
                                required
                                autocomplete="none"
                                :class="{'is-invalid' : hasWarning('add', 'company')}"
                            />
                        </modal-field>

                        <modal-field
                            label="Address ID"
                            class="mb-4 w-full"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="address.unifaun_address_id"
                                autocomplete="none"
                                :class="{'is-invalid' : hasWarning('add', 'unifaun_address_id')}"
                            />
                        </modal-field>
        
                        <modal-field
                            :label="'Address line 1'"
                            required
                            :class="'mb-4 w-full'"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="address.add.address"
                                required
                                autocomplete="none"
                                :class="{'is-invalid' : hasWarning('add', 'address')}"
                            />
                        </modal-field>

                        <modal-field
                            :label="'Address line 2'"
                            :class="'mb-4 w-full'"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="address.add.address2"
                                autocomplete="none"
                            />
                        </modal-field>
        
                        <modal-field
                            :label="'Zip code'"
                            required
                            :class="'w-1/3 mb-4 pr-4'"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="address.add.zip"
                                required
                                autocomplete="none"
                                :class="{'is-invalid' : hasWarning('add', 'zip')}"
                            />
                        </modal-field>
        
                        <modal-field
                            :label="'City'"
                            required
                            :class="'w-2/3 mb-4'"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="address.add.city"
                                required
                                autocomplete="none"
                                :class="{'is-invalid' : hasWarning('add', 'city')}"
                            />
                        </modal-field>
        
                        <modal-field
                            :label="'State'"
                            :class="'w-2/4 pr-4 mb-4'"
                        >
                            <input
                                type="text"
                                class="mt-1"
                                v-model="address.add.state"
                                autocomplete="none"
                            />
                        </modal-field>
        
                        <modal-field
                            :label="'Country'"
                            required
                            :class="'w-2/4 mb-4'"
                        >
                            <multiselect
                                v-model="address.add.country"
                                class="mt-1"
                                :options="countries"
                                track-by="value"
                                label="name"
                                :class="{'is-invalid' : hasWarning('add','country')}"
                            >
                            </multiselect>
                        </modal-field>
        
                        <modal-field
                            :label="'Contact type'"
                            required
                            :class="'w-full mb-4'"
                        >
                            <multiselect
                                v-model="address.add.types"
                                :options="companyTypeDictionary"
                                class="mt-1"
                                track-by="value"
                                label="name"
                                multiple
                                :class="{'is-invalid' : hasWarning('add','types')}"
                                open-direction="top"
                            >
                            </multiselect>
                        </modal-field>

                        <modal-field
                            label="Default contact"
                            :class="'w-full mb-4'"
                            :bodyClass="'flex flex__align-center'"
                        >
                            <div class="w-full">
                                <autocomplete
                                    v-if="address.add.contact_person && !this.address.add.create_contact"
                                    v-model="address.add.contact_person.name"
                                    :endpoint="contactSearchEndpoint"
                                    :params="{ organization_id: address.add.organization ? address.add.organization.id : '' }"
                                    required
                                    direction="top"
                                    :body-class="['mt-1']"
                                    :disabled="this.address.add.create_contact"
                                    @click="updateContactPerson"
                                >
                                    <template v-slot="slotProps">
                                        <span v-if="slotProps.data.default" class="mr-2 text-gray-600">(default)</span>
                                        {{ slotProps.data.name }}
                                        <span class="mr-2 text-gray-600" v-text="slotProps.data.email" />
                                    </template>
                                </autocomplete>
                                <autocomplete
                                    v-else-if="address.add.contact_person && this.address.add.create_contact"
                                    required
                                    :body-class="['mt-1']"
                                    disabled
                                />
                            </div>
                            <button
                                type="button"
                                class="flex self-center ml-2 text-2xl cursor-pointer"
                                :key="`create_contact_${address.add.create_contact}`"
                                @click="toggleCreateContact"
                            >
                                <svg-importer icon-name="icons/add-user" key="contacts_book" />
                            </button>
                        </modal-field>

                        <div v-if="address.add.create_contact" class="w-full">
                            <modal-field
                                label="Full name"
                                required
                                class="mb-4 w-full"
                            >
                                <input
                                    type="text"
                                    class="mt-1"
                                    v-model="address.add.contact_person.name"
                                    required
                                    autocomplete="none"
                                />
                            </modal-field>

                            <modal-field
                                label="Phone"
                                required
                                class="mb-4 w-full"
                            >
                                <the-mask
                                    mask="+#############"
                                    class="mt-1"
                                    v-model="address.add.contact_person.phone"
                                    required
                                />
                            </modal-field>

                            <modal-field
                                label="E-mail"
                                class="mb-4 w-full"
                            >
                                <input
                                    type="text"
                                    class="mt-1"
                                    v-model="address.add.contact_person.email"
                                    autocomplete="none"
                                />
                            </modal-field>
                        </div>

                        <modal-field
                            label="Delivery instruction"
                            class="mb-4 w-full"
                        >
                            <autocomplete
                                v-model="address.add.delivery_instruction"
                                :endpoint="getInstructionsEndpoint"
                                :filters="getInstructionsFilter('delivery')"
                                :maxlength="getMaxLength('delivery_instruction')"
                                direction="top"
                                @input="updateInstructionValue('delivery_instruction', $event)"
                                @click="setInstructionValue('delivery_instruction', $event)"
                            >
                                <template v-slot="slotProps">
                                    {{ slotProps.data.instruction }}
                                </template>
                            </autocomplete>
                        </modal-field>

                        <modal-field
                            label="Pickup instruction"
                            class="mb-4 w-full"
                        >
                            <autocomplete
                                v-model="address.add.pickup_instruction"
                                :endpoint="getInstructionsEndpoint"
                                :filters="getInstructionsFilter('pickup')"
                                :maxlength="getMaxLength('pickup_instruction')"
                                direction="top"
                                @input="updateInstructionValue('pickup_instruction', $event)"
                                @click="setInstructionValue('pickup_instruction', $event)"
                            >
                                <template v-slot="slotProps">
                                    {{ slotProps.data.instruction }}
                                </template>
                            </autocomplete>
                        </modal-field>
                    </div>
        
                    <div slot="footer" class="flex w-full">
                        <button
                            class="ml-auto mr-4 text-sm text-red-500"
                            @click="hideAddAddressBookModal"
                        >Cancel</button>
                        <button v-if="!(typeAddressBook === 'edit' && currentStep === 4)" class="btn" @click="addToCompanies">{{ addressBookTitles.button }}</button>
                    </div>
                </modal>

                <transition name="fade">
                    <contacts-book
                        :show="showContactsBook"
                        v-model="owner"
                        :organizations="organizations"
                        :selected-contact="selectedContact[addressParent]"
                        :address-parent="addressParent"
                        @click="markContact"
                        @dblclick="editContact"
                        @hide="closeContactsBookModal"
                        @choose="chooseContact"
                        @open-add-contact="editContact"
                    />
                </transition>

                <transition name="fade">
                    <contact-edit-modal
                        v-model="contactEdit"
                        :show="showEditContactModal"
                        :organizations="organizationsList"
                        @hide="hideEditContactModal"
                    />
                </transition>
            </div>
        </dropdown>
    </div>
</template>

<script>
    import DatePicker from "~/components/DatePicker";
    import moment from 'moment'
    import axios from "axios";
    import TInput from "../../../components/TInput";
    import { validateEmailPlt } from '~/data/regex';

    import AddressBook from './AddressBook.vue';
    import ContactsBook from './ContactsBook.vue';
    import ContactEditModal from './ContactEditModal.vue';
    import AppSpinner from '~/components/AppSpinner.vue';
    import { requestErrors } from '~/utils/errors';
    import Autocomplete from '~/components/Autocomplete.vue';
    import SpinnerBlock from '~/components/SpinnerBlock.vue';
    import Dropdown from '~/components/DropdownBlock.vue';

    import DateTimePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    import lockedMixin from './locked.mixin.js';
    import IsTemplateMixin from '~/mixins/isTemplate.mixin';
    import { FLOW_TYPE_MAP } from "~/store/modules/price-leadtime";

    const DEFAULT_ADDRESS_ADD = {
        id: null,
        organization: {},
        address: null,
        name: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        types: [],
        contact_person: {
            id: null,
            name: '',
            email: '',
            phone: '',
        },
        create_contact: false,
    };

    const sectionNameToCompanyTypeNameDict = {
        consignor: 'sender',
        pickup: 'pickup',
        consignee: 'receiver',
        delivery: 'delivery',
    };

    export default {
        name: "PriceLeadtimeAddress",

        mixins: [lockedMixin, IsTemplateMixin],

        components: {
            TInput,
            DatePicker,
            AddressBook,
            ContactsBook,
            AppSpinner,
            Autocomplete,
            ContactEditModal,
            SpinnerBlock,
            Dropdown,
            DateTimePicker,
        },

        props: {
            proxio: {
                default: null,
            },
            organizations: {
                default: () => { return [] },
            },
            componentCounterProp: Number,
        },

        data: () => ({
            flowTypeMap: FLOW_TYPE_MAP,
            isCitiesLoading: false,
            isAddressBookLoading: false,
            cities: [],
            organizationsList: [],
            loadingOrganizationsList: false,
            textLimitMessage: 'We recommend you to limit address with 30 symbols as some carriers do not accept address lines that are longer',
            warnings: {},
            showAddressTable: false,
            showAddAddressBookModal: false,
            showEditContactModal: false,
            showContactsBook: false,
            shortFlowRadio: FLOW_TYPE_MAP.quote,
            address: {
                add: {...DEFAULT_ADDRESS_ADD },
            },
            contactEdit: {},
            addressParent: null,
            companies: [],
            contact_types: [
                "Shipment",
            ],

            selectedCompany: {
                delivery: {},
                pickup: {},
                consignee: {},
                consignor: {}
            },
            selectedContact: {
                delivery: {},
                pickup: {},
                consignee: {},
                consignor: {}
            },
            addressSections: ["pickup", "delivery", 'consignee', 'consignor'],
            defaults: {
                addressSections: ["pickup", "delivery", 'consignee', 'consignor'],
                address: {
                    add: {
                        contact_type: 'Shipment'
                    },
                    pickup: {}, 
                    delivery: {},
                    consignee: {},
                    consignor: {}
                },
                selectedCompany: {
                    delivery: {},
                    pickup: {},
                },
            },
            typeAddressBook: 'add',
            owner: null,
            countriesList: {
                pickup: [],
                delivery: [],
                consignee: [],
                consignor: []
            },
            fillData: {
                pickup: {},
                delivery: {},
                consignee: {},
                consignor: {}
            },
            timeoutContact: null,
            timeoutCompanyAddress: null,
            payerTypes: [
                { id: 'sender', name: 'Sender'},
                { id: 'receiver', name: 'Receiver'},
                { id: 'third_party', name: 'Third party'},
            ],
            addressBookRef: 'address-book',
        }),

        computed: {
            addressDropdownTitleText() {
                const base = 'Addresses';
                const addresses = [
                    { prefix: 'from', value: this.addressData?.pickup?.name },
                    { prefix: 'to', value: this.addressData?.delivery?.name },
                ]

                const addressesStrings = addresses.reduce((accum, address) => {
                    if (address.value) {
                        accum.push(`${address.prefix} ${address.value}`);
                    }

                    return accum;
                }, []);

                const postfix = addressesStrings.filter(val => val).join(' ');
                
                return base + (postfix ? ` (${postfix})` : '');
            },
            addressData() {
                return this.$store.getters[`price-leadtime/address`]
            },
            isShortFlow() {
                return this.$store.getters['price-leadtime/isShortFlow'];
            },
            isPltFlow() {
                return this.$store.getters['price-leadtime/isPltFlow'];
            },
            adminLevel() {
                return this.$store.getters['auth/isAdmin'];
            },
            countries() {
                return this.$store.getters['countries/list'].map(country => {
                    return { name: `${country.code} - ${country.name}`, value: country.code };
                });
            },
            user() {
                return this.$store.getters["auth/user"];
            },
            isGodUser() {
                return this.$store.getters["auth/isGodUser"];
            },
            currentSites() {
                return this.$store.getters["auth/sites"];
            },
            data() {
                return this.$store.getters[`price-leadtime/address`]
            },
            deliveryTermsData() {
                return this.$store.getters['price-leadtime/delivery_terms'];
            },
            currentStep() {
                return this.$store.getters["price-leadtime/currentStep"];
            },
            currentStepKey() {
                return this.steps[this.currentStep].key
            },
            steps() {
                return this.$store.getters['price-leadtime/activeSteps']
            },
            errors() {
                return this.$store.getters['price-leadtime/activeSteps'][this.currentStep].errors
            },
            isSetupProxio() {
                return this.$store.getters['price-leadtime/hasSetupProxio'];
            },
            hideAllBtn() {
                if (!this.addressParent) {
                    return [];
                }

                if (!this.owner) {
                    return this.companies;
                }

                return this.companies.filter(item => item.organization_id === this.owner.id);
            },
            addressBookTitles() {
                if (this.typeAddressBook === 'edit') {
                    return { title: 'Edit address book', button: 'Update' }
                }

                if (this.typeAddressBook === 'dublicate') {
                    return { title: 'Dublicate to address book', button: 'Update' }
                }

                return { title: 'Add to address book', button: 'Add' }
            },
            isCreateBooking() {
                return this.currentStepKey === 'create_booking';
            },
        },

        async created() {
            if (!this.isTemplate) {
                // ~/pages/transport/PriceLeadTimeFormTemplate.vue has its own
                // countries initialization
                await this.$store.dispatch('countries/init');
            }

            this.fetchCities();
            this.setFillData();
            this.setDefaults();
            this.showSectionsIfFilled();
            this.setupProxio();

            this.owner = this.currentSites.map(site => site.organization)[0];
        },

        methods: {
            getCompanyNameFilters(sectionName) {
                return this.companyTypeDictionary.find(type => type.name.toLowerCase() === sectionNameToCompanyTypeNameDict[sectionName]).value;
            },
            getInstructionsEndpoint(val) {
                if (!val) {
                    return `${this.$apiUrl.consignments.bookingInstructions}?`; 
                }

                return `${this.$apiUrl.consignments.bookingInstructions}?filter[instruction]=${val}`;
            },
            getMaxLength(key) {
                if (key === 'pickup_instruction') {
                    return '96';
                }

                if (key === 'delivery_instruction') {
                    return '90';
                }

                return '';
            },
            updateInstructionValue(key, event) {
                this.address.add[key] = event;
                this.address.add[`${key}_id`] = null;
            },
            setInstructionValue(key, value) {
                this.address.add[key] = value.instruction;
                this.address.add[`${key}_id`] = value.id;
            },
            getInstructionsFilter(key) {
                return {
                    organization_id: this.user?.organization?.id,
                    type: key,
                };
            },
            updatePickupTime(key, value) {
                this.$store.commit('price-leadtime/SET_PICKUP_TIME', { key, value });
            },
            updateContactPerson(data) {
                this.address.add.contact_person = data;
            },
            async toggleCreateContact() {
                this.address.add.create_contact = !this.address.add.create_contact;

                await this.$forceUpdate();
            
                if (!this.address.add.create_contact) {
                    return;
                }

                this.address.add.contact_person = { ...DEFAULT_ADDRESS_ADD.contact_person };

                this.$nextTick(() => {
                    const modalBodyElement = this.$refs.addAddressModalBody.parentElement;
                    modalBodyElement.scrollTop = modalBodyElement.scrollHeight;
                });
            },
            reset(section) {
                this.$store.commit('price-leadtime/RESET_ADDRESS_BY_TYPE', section);
            },
            contactSearchEndpoint(val) {
                return `${this.$apiUrl.addressBook.contact}/search?name=${val}`;
            },
            getCompleteAddress(company) {
                if (company.complete_address) {
                    return company.complete_address;
                }


                return [
                    company.address,
                    this.cities.find(city => city.id === company.city_id)?.name,
                ]
                .filter(val => val)
                .join(', ');
            },
            validateEmail(section) {
                if (!this.data[section].email) {
                    return true;
                }

                return validateEmailPlt(this.data[section].email);
            },
            copyConsignor() {
                this.data.pickup = {
                    pickup_date: this.data.pickup.pickup_date,
                    pickupTimeEarliest: this.data.pickup.pickupTimeEarliest,
                    pickupTimeLatest: this.data.pickup.pickupTimeLatest,
                    ...this.data.consignor
                };
                
                this.$store.commit(
                    'price-leadtime/SET_DELIVERY_TERMS_FIELD',
                    { field: 'pickup_instructions', value: { instruction: this.data.consignor.pickup_instruction?.instruction } }
                );
            },
            copyConsignee() {
                this.data.delivery = { ...this.data.consignee };
                
                this.$store.commit(
                    'price-leadtime/SET_DELIVERY_TERMS_FIELD',
                    { field: 'delivery_instructions', value: { instruction: this.data.consignee.delivery_instruction?.instruction } }
                );
            },
            async fetchCities() {
                this.isCitiesLoading = true;

                try {
                    const { data: { data } } = await axios.get('/api/cities');
                    this.cities = data;
                } catch (_) {
                    this.$snotify('Cities fetch error')
                }

                this.isCitiesLoading = false;
            },
            updateFlow() {
                this.$store.commit('price-leadtime/SET_FLOW_TYPE', this.shortFlowRadio);
            },
            getCapitalizedSection(section) {
                return section[0].toUpperCase() + section.substr(1);
            },
            setFillData(){
                if (this.currentStepKey === 'create_booking') {
                    this.fillData = JSON.parse(JSON.stringify(this.data));
                }
            },
            disabledForm(value) {
                return this.currentStepKey === 'create_booking' && Boolean(value);
            },
            searchCountry(value, section) {
                const values = this.countries.filter(item => {
                    return item.value?.toLowerCase() === value?.toLowerCase()
                });

                const names = this.countries.filter(item => {
                    return item.name?.toLowerCase().includes(value?.toLowerCase());
                })

                this.countriesList[section] = values.length ? values : names;
            },
            findCountry(value) {
                return this.countries.find(item => {
                    return value === item.value;
                });
            },
            async editAddress(value) {
                const section = this.addressParent;

                this.addressParent = section;
                await this.showAddAddress(this.addressParent);

                this.address.add.id = value.id;
                this.address.add.address = value.address;
                this.address.add.address2 = value.address2;
                this.address.add.name = value.name;
                this.address.add.city = value.city;
                this.address.add.state = value.state;
                this.address.add.zip = value.postcode;
                this.address.add.country = this.findCountry(value.country_code);
                this.address.add.types = this.companyTypeDictionary.filter(item => {
                    return value.types.find(type => { return type === item.value });
                });
                this.address.add.contact_person = value.contact;

                this.address.add.pickup_instruction_id = value.pickup_instruction_id;
                this.address.add.pickup_instruction = value.pickup_instruction?.instruction || '';
                this.address.add.delivery_instruction_id = value.delivery_instruction_id;
                this.address.add.delivery_instruction = value.delivery_instruction?.instruction || '';

                this.typeAddressBook = 'edit'; 
            },
            editContact(value) {
                const section = this.addressParent;

                this.addressParent = section;

                this.contactEdit = {
                    id: value?.id || null,
                    organization_id: value?.organization_id || this.user.organization.id,
                    organization: this.organizationsList?.find(org => org.id === value?.organization_id) || this.user.organization,
                    name: value?.name || null,
                    email: value?.email || null,
                    phone: value?.phone || null,
                    address: value?.address || null,
                };

                this.showEditContactModal = true;
            },
            autocompleteBlur(section) {
                if (['pickup', 'delivery'].includes(section)) {
                    return;
                }

                const payer = this.$store.getters['price-leadtime/payer'];
                
                if (payer.consignor === null) {
                    this.$store.commit('price-leadtime/SET_CONSIGNOR_PAYER', false);
                }

                if (payer.consignee === null) {
                    this.$store.commit('price-leadtime/SET_CONSIGNEE_PAYER', false);
                }
            },
            selectAutoFill(value, section) {
                const city = this.cities.find(cityItem => cityItem.id === value.city_id);

                if (city) {
                    value.city = city.name;
                    value.country_code = city.country_code;
                }

                this.selectedCompany[section] = value;
                this.fillAddressInfoWithCompany(section);
                this.fillContactInfoWithCompany(section);
            },
            selectContactAutoFill(value, section) {
                this.data[section].contact_person = value.name;
                this.data[section].phone = value.phone;
                this.data[section].email = value.email;
            },
            setContact(parent) {
                var contact = this.selectedContact[parent];

                this.data[parent].contact_person = contact.name;
                this.data[parent].email = contact.email;
                this.data[parent].phone = contact.phone;
            },
            hourValue(index) {
                var value;
                index = index - 1;

                if(index < 10) {
                    value = '0' + index + ':00';
                } else {
                    value = index + ':00';
                }

                return value;
            },
            showSectionsIfFilled() {
                if(Object.values(this.data.delivery).filter(field => field != null).length > 0) {
                    this.addressSections.push('delivery');
                }

                if(Object.values(this.data.pickup).filter(field => field != null).length > 0) {
                    this.addressSections.push('pickup');
                }
            },
            markCompany(company) {
                this.selectedCompany[this.addressParent] = company;
            },
            markContact(contact) {
                this.selectedContact[this.addressParent] = contact;
            },
            clone(obj){
                return JSON.parse(JSON.stringify(obj))
            },
            setDefaults() {
                this.addressSections = this.clone(this.defaults.addressSections);
                this.address = this.clone(this.defaults.address);
            },
            closeAddressTableModal() {
                this.showAddressTable = false;
                this.addressParent = null;
            },
            closeContactsBookModal() {
                this.showContactsBook = false;
                this.addressParent = null;
                this.selectedContact[this.addressParent] = {};
            },
            openAddressModal(parent) {
                if (this.lockedFields[`${parent}_address`]) {
                    return;
                }

                this.showAddressTable = true;
                this.addressParent = parent;
                this.selectedCompany[parent] = null;
            },
            async openContactModal(parent) {
                this.showContactsBook = true;

                await this.fetchOrganizations();

                this.addressParent = parent;
                this.selectedContact[parent] = null;
            },
            async showAddAddress(parent) {
                if (this.isGodUser) {
                    this.fetchOrganizations();
                }

                this.typeAddressBook = 'add';
                this.addressParent = parent;

                this.address.add = {
                    ...this.data[parent],
                    organization: this.isGodUser ? this.owner : this.user.organization,
                    contact_person: { name: this.data[parent].contact_person },
                    delivery_instruction: this.data[parent].delivery_instruction,
                    delivery_instruction_id: this.data[parent].delivery_instruction,
                    pickup_instruction: this.data[parent].pickup_instruction,
                    pickup_instruction_id: this.data[parent].pickup_instruction,
                }; 

                this.showAddAddressBookModal = true;

                if (!this.data[parent].contact_person) {
                    return;
                }

                try {
                    const { data: { data } } = await axios.get(
                        `${this.contactSearchEndpoint(this.data[parent].contact_person)}&organization_id=${this.address.add.organization}`
                    );
    
                    if (!data?.length) {
                        return;
                    }

                    const matchedContact = data.find(item => item.name === this.data[parent].contact_person);

                    if (!matchedContact) {
                        return;
                    }

                    this.address.add.contact_person.id = matchedContact?.id || null;
                    this.address.add.contact_person.name = matchedContact?.name || '';

                } catch (error) {
                    console.error(error);
                }
            },
            hideAddAddressBookModal() {
                this.showAddAddressBookModal = false;

                this.address.add = {};
                this.organizationsList = [];

            },
            hideEditContactModal(data) {
                if (data) {
                    this.chooseContact(data);
                }

                this.showEditContactModal = false;
                this.contactEdit = {};
            },
            hasWarning(section, key) {
                return this.errors[section + "." + key];
            },
            async fetchOrganizations() {
                this.loadingOrganizationsList = true;

                try {
                    const { data } = await axios.get(`${this.$apiUrl.organizations.base}?pageSize=1000`);
                    this.organizationsList = data.data;
                } catch (error) {
                    this.$snotify.error(requestErrors(error));
                }

                this.loadingOrganizationsList = false;

            },
            companyIsSelected(section) {
                var object = this.selectedCompany[section];

                var result = Object.keys(object).length === 0 && object.constructor === Object;
                // result should be true which means Object is empty
                return !result;
            },
            toggleSection(section) {

                this.data[section].active = !this.data[section].active

                if (!this.addressSections.includes(section)) {
                    this.addressSections.push(section);
                } else {
                    let idx = this.addressSections.indexOf(section);
                    this.addressSections.splice(idx, 1);
                    //this.data[section] = {};
                }
            },
            fillAddressInfoWithCompany(parent) {
                if(parent) {
                    this.addressParent = parent;
                }

                // clear fields
                this.address[this.addressParent] = {};

                if(!this.addressParent || !this.selectedCompany) {
                    return;
                }
                
                const data = this.selectedCompany[this.addressParent];
                const info = this.data[this.addressParent];

                this.data[this.addressParent].company_model = data
                info.name = data.name;
                info.address = data.address;
                info.address2 = data.address2;
                info.zip = data.postcode;
                info.city = data.city;
                info.state = data.state;
                info.country = this.findCountry(data.country_code);
                info.unifaun_address_id = data.unifaun_address_id;
                info.contact_person = data.contact;
                info.pickup_instruction = data.pickup_instruction;
                info.pickup_instruction_id = data.pickup_instruction_id;
                info.pickup_delivery = data.pickup_delivery;
                info.delivery_instruction_id = data.delivery_instruction_id;

                if (['consignor', 'consignee'].includes(this.addressParent)) {
                    const commitName = `price-leadtime/SET_${this.addressParent.toUpperCase()}_PAYER`;
                    const commitValue = this.selectedCompany[this.addressParent][`${this.addressParent}_payer`];

                    this.$store.commit(commitName, commitValue);
                }

                ['pickup', 'delivery'].forEach(async (addressType) => {
                    if (this.addressParent !== addressType) {
                        return;
                    }
                    
                    this.$store.commit(
                        'price-leadtime/SET_DELIVERY_TERMS_FIELD',
                        { field: `${addressType}_instructions`, value: { instruction: info[`${addressType}_instruction`]?.instruction } }
                    );
                });
            },
            fillContactInfoWithCompany(parent) {
                if(parent) {
                    this.addressParent = parent;
                }

                // clear fields
                this.address[this.addressParent] = {};

                if(!this.addressParent || !this.selectedCompany || !this.selectedCompany[this.addressParent]?.contact) {
                    return;
                }
                
                const data = this.selectedCompany[this.addressParent];
                const info = this.data[this.addressParent];

                info.contact_person = data.contact.name;
                info.email = data.contact.email;
                info.phone = data.contact.phone;

                if (['consignor', 'consignee'].includes(this.addressParent)) {
                    const commitName = `price-leadtime/SET_${this.addressParent.toUpperCase()}_PAYER`;
                    const commitValue = this.selectedCompany[this.addressParent][`${this.addressParent}_payer`];

                    this.$store.commit(commitName, commitValue);
                }
            },
            dublicateCompany() {
                const section = this.addressParent;
                const selected = this.selectedCompany[section]

                this.closeAddressTableModal();

                this.addressParent = section;
                this.showAddAddress(this.addressParent);
                
                this.address.add.name = '';
                this.address.add.address = selected.address;
                this.address.add.address2 = selected.address2;
                this.address.add.city = selected.city;
                this.address.add.state = selected.state;
                this.address.add.zip = selected.postcode;
                this.address.add.country = this.findCountry(selected.country_code);
                this.address.add.types = this.companyTypeDictionary.filter(item => {
                    return selected.types.find(type => { return type === item.value });
                });

                 this.typeAddressBook = 'dublicate'; 
            },
            chooseCompany() {
                this.fillAddressInfoWithCompany();
                this.fillContactInfoWithCompany();

                if (this.showAddressTable) {
                    this.closeAddressTableModal();
                }
            },
            chooseContact(contactData) {
                this.showContactsBook = false;
                
                const data = contactData || this.selectedContact[this.addressParent];
                const info = this.data[this.addressParent];

                info.contact_person = data.name;
                info.email = data.email;
                info.phone = data.phone;
            },
            async addToCompanies() {
                var postData = this.address.add;
                
                if (
                    !postData.organization ||
                    !postData.types.length ||
                    !postData.address ||
                    !postData.country ||
                    !postData.city ||
                    !postData.zip ||
                    !postData.name
                ) {
                    this.$snotify.error('Fill in required fields.');
                    
                    return;
                }

                this.address.add.loading = true;

                if (postData.create_contact) {
                    if (!postData.contact_person.name) {
                        this.$snotify.error('Fill in required fields.');

                        return;
                    }

                    try {
                        const { data: { data } } = await axios.post(this.$apiUrl.addressBook.contact, {
                            organization_id: postData.organization.id,
                            name: postData.contact_person.name,
                            email: postData.contact_person.email,
                            phone: postData.contact_person.phone,
                        });

                        postData.contact_person.id = data.id;

                        this.$refs[this.addressBookRef].fetchCompanies();
                    } catch(error) {
                        this.$snotify.error(requestErrors(error));

                        this.address.add.loading = false;

                        return;
                    }
                }

                try {
                    const url = postData.id ? `/api/address-book/${postData.id}` : `/api/address-book`;

                    const payloadData = {
                        organization_id: postData.organization.id,
                        address: postData.address,
                        address2: postData.address2,
                        country_code: postData.country.value,
                        city: postData.city,
                        postcode: postData.zip,
                        name: postData.name,
                        types: postData.types?.map(item => { return item.value }),
                        contact_id: postData.contact_person?.id,
                        delivery_instruction: postData.delivery_instruction,
                        delivery_instruction_id: postData.delivery_instruction_id,
                        pickup_instruction: postData.pickup_instruction,
                        pickup_instruction_id: postData.pickup_instruction_id,
                    };

                    if (postData.state) {
                        payloadData.state = postData.state;
                    }

                    const { data: { data } } = await axios.post(url, payloadData);

                    if (!postData.id) {
                        this.selectedCompany[this.addressParent] = {
                            ...data,
                            city: data.city.name,
                            country_code: data.city.country_code,
                            contact: postData.contact_person && {
                                id: postData.contact_person.id,
                                name: postData.contact_person.name,
                                email: postData.contact_person.email,
                                phone: postData.contact_person.phone,
                            } || null,
                        };

                        this.chooseCompany();
                    }

                    if (data.city) {
                        this.cities.push(data.city);
                    }

                    this.$refs[this.addressBookRef].fetchCompanies();
                    this.hideAddAddressBookModal();
                    
                    this.$snotify.success(`Saved to companies!`);
                } catch(error) {
                    const errors = error?.response?.data?.errors

                    const messages = [];

                    for (let key in errors) {
                        messages.push(`${key}: ${errors[key].join(', ')}`);
                    }

                    this.$snotify.error(messages.join(', '));
                }

                this.address.add.loading = false;
            },

            setupProxioGoods() {
                if (!this.proxio) {
                    return;
                }

                const params = this.proxio.originalParams;

                const goods = this.$store.getters[`price-leadtime/goods`].items;

                goods[0].units = params.noofpackages;
                goods[0].load_meters = params.dimensions_loadingmetres;
                goods[0].weight = params.dimensions_weight;
                goods[0].volume = params.dimensions_volume;
                goods[0].pallet_spaces = params.dimensions_palletspace;
            },

            setupProxio() {
                if (!this.proxio) {
                    return;
                }

                if (this.isSetupProxio) {
                    return;
                }

                this.setupProxioGoods();

                this.data['pickup'].zip = this.proxio.fromZipCode;
                this.data['pickup'].city = this.proxio.fromCity;
                this.data['pickup'].country = this.findCountry(this.proxio.fromCountryCode);
                this.data['pickup'].address = this.proxio.fromAddress;
                this.data['pickup'].name = this.proxio.fromCompany;
                this.data['pickup'].pickup_date = moment(this.proxio.transportDate).format('Y-MM-DD');

                this.data['delivery'].zip = this.proxio.toZipCode;
                this.data['delivery'].city = this.proxio.toCity;
                this.data['delivery'].country = this.findCountry(this.proxio.toCountryCode);
                this.data['delivery'].address = this.proxio.toAddress;
                this.data['delivery'].name = this.proxio.toCompany;

                this.$store.commit(`price-leadtime/SETUP_PROXIO`);
            },
            async getInstruction(id) {
                try {
                    const res = await axios.get(`${this.$apiUrl.consignments.bookingInstructions}/${id}`);

                    return res.data?.data;
                } catch(error) {
                    return { error: true };
                }
            },
            async dataWatcherHander(key, value) {
                const idKey = `${key}_id`;
                if (!value[idKey]) {
                    return;
                }

                if (value[idKey] && !this.$store.getters['price-leadtime/delivery_terms'][`${key}s`]?.id) {
                    this.$store.commit('price-leadtime/SET_DELIVERY_TERMS', {
                        key: `${key}s`,
                        value: await this.getInstruction(value[idKey]),
                    });
                }
            },
        },

        watch: {
            proxio() {
                this.setupProxio();
            },
            'data.pickup': {
                deep: true,
                handler(value) {
                    this.dataWatcherHander('pickup_instruction', value)
                },
            },
            'data.delivery': {
                deep: true,
                handler(value) {
                    this.dataWatcherHander('delivery_instruction', value)
                },
            },
        }
    }
</script>

<style lang="scss">
.address-book-button:disabled {
    background: transparent !important;
    svg path {
        stroke: var(--color-border);
    }
}

.border-red-500 {
    border-color: #f56565 !important;
}

.modal-add-address .modal__body {
    overflow: inherit;
}

.cinput-autocomplete {
    &__el { 
        position: absolute;
        left: 0;
        top: calc(100%);
        width: 100%;
        border: 1px solid #E6E6E6;
        background: white;
        z-index: 11;
        overflow-y: auto;
        max-height: calc(39px * 6);
    }

    &__item {
        font-size: 0.75rem;
        display: block;
        padding: 6px 12px;
        min-height: 2rem;
        line-height: 1.6;
        text-decoration: none;
        text-transform: none;
        vertical-align: middle;
        position: relative;
        cursor: pointer;

        &:hover {
            background-color: var(--brand-color) !important;
            color: white;
        }
    }
}
</style>

<template>
    <div class="spinner-block">
        <spinner />
    </div>
</template>
<script>
import Spinner from './Spinner.vue';

export default {
    name: 'SpinnerBlockComponent',
    components: { Spinner },
};
</script>
<style lang="scss">
.spinner-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;

    display: grid;
    place-items: center;

    z-index: 1;
}
</style>
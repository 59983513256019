<template>
    <div class="ml-auto">
        <div class="flex items-center ml-auto tools">
            <confirm class="mr-4" confirm-text="Reset form" @confirmed="resetData">
                <button class="hover:text-red-500">
                    <fa class="mr-1" :icon="['fal','undo']"/>
                    Reset
                </button>
            </confirm>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Queries',

    computed: {
        flowType() {
            return this.$store.getters['price-leadtime/flowType'];
        },
    },

    methods: {
        resetData() {
            this.$store.commit('price-leadtime/resetState', { flowType: this.flowType })
            window.scrollTo(0, 0);
            this.$store.commit('price-leadtime/SET_CURRENT_STEP', 0)
        },
    }
}
</script>



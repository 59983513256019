<template>
    <modal :show="show" :closeable="false" :hide-header="true" size="sm">
        <div slot="body" class="pt-6">
            <p class="text-center">
                Unfortunately we can not provide you with automatic price and lead times calculation for specified route.
            </p>
        </div>
        <div slot="footer" class="flex w-full justify-center">
            <button class="btn" @click="$emit('start-over')">Start over</button>
        </div>
    </modal>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<template>
    <modal :show="show" :click-to-close="false" @hide="$emit('hide')" size="sm" class="modal-add-address">
        <h2 slot="header" v-text="title" />
        <div slot="body" class="flex flex-wrap relative">
            <div v-if="loading" class="spinner-wrapper">
                <spinner />
            </div>
            <modal-field
                label="Organization"
                :required="true"
                :class="'mb-4 w-full'"
            >
                <multiselect
                    :value="contactLocal.organization"
                    class="mt-1"
                    :options="organizations"
                    :disabled="!isGodUser || edit"
                    track-by="id"
                    label="name"
                    @input="updateOrganization"
                >
                </multiselect>
            </modal-field>
            <modal-field
                label="Full name"
                required
                class="mb-4 w-full"
            >
                <input
                    type="text"
                    class="mt-1"
                    :class="{ 'border-red-500': errors.includes('name') }"
                    v-model="contactLocal.name"
                    required
                    autocomplete="none"
                />
            </modal-field>

            <modal-field
                label="Phone"
                required
                class="mb-4 w-full"
            >
                <the-mask
                    mask="+#############"
                    class="mt-1"
                    :class="{ 'border-red-500': errors.includes('phone') }"
                    v-model="contactLocal.phone"
                    required
                />
            </modal-field>

            <modal-field
                label="E-mail"
                class="mb-4 w-full"
            >
                <input
                    type="text"
                    class="mt-1"
                    :class="{ 'border-red-500': (!isEmailFocused && !isValidEmail) || errors.includes('email') }"
                    v-model="contactLocal.email"
                    autocomplete="none"
                    @focus="isEmailFocused = true"
                    @blur="isEmailFocused = false"
                />
            </modal-field>

            <modal-field
                label="Linked as default contact to address"
                class="w-full mb-4 relative"
            >
                <span
                    v-if="addressOptionsLoading"
                    class="h-4 inline-block text-transparent linear-background w-full mt-2 mb-3"
                >
                    Loading
                </span>
                <Multiselect
                    v-else
                    v-model="contactLocal.address"
                    :options="addressOptions"
                    class="mt-1"
                    label="name"
                    multiple
                    track-by="id"
                    open-direction="top"
                >
                    <template slot="option" slot-scope="props">
                        {{ props.option.name }}
                        <span v-if="props.option.contact_id" class="text-gray-500 ml-2">
                            has default contact
                        </span>
                    </template>
                </Multiselect>
            </modal-field> 
        </div>

        <div slot="footer" class="flex w-full">
            <button
                class="ml-auto mr-4 text-sm text-red-500"
                @click="$emit('hide')"
            >
                Cancel
            </button>
            <button class="btn" @click="updateContacts" v-text="btnText" />
        </div>
    </modal>
</template>
<script>
import axios from 'axios';
import { requestErrors } from '~/utils/errors';
import Spinner from '@/components/Spinner.vue';
import { validateEmailPlt } from '~/data/regex';
import Multiselect from 'vue-multiselect'

export default {
    props: {
        show: Boolean,
        contact: {
            type: Object,
            default: () => ({}),
        },
        organizations: {
            type: Array,
            default: () => [],
        },
    },

    components: { Spinner, Multiselect },

    model: {
        prop: 'contact',
        event: 'update:contact',
    },

    watch: {
        show() {
            this.resetErrors();
            this.addressOptionsLoading = false;
        },
        contact: {
            deep: true,
            immediate: true,
            handler(val, oldVal) {
                if (val?.organization_id === oldVal?.organization_id) {
                    return;
                }
    
                this.fetchAddressOptions();
            }
        }
    },

    data() {
        return {
            loading: false,
            addressOptions: [],
            isEmailFocused: false,
            errors: [],
            addressOptionsLoading: false,
        };
    },

    computed: {
        userOrganization() {
            return this.$store.getters['auth/user'].organization;
        },
        edit() {
            return Boolean(this.contact?.id);
        },
        title() {
            return this.edit ? 'Edit contact book' : 'Add to contact book';
        },
        btnText() {
            return this.edit ? 'Update' : 'Save';
        },
        contactLocal: {
            set(val) {
                this.$emit('update:contact', {
                    id: val.id,
                    name: val.name,
                    phone: val.phone,
                    email: val.email.split(',').trim().join(','),
                    organization: val.organization,
                    organization_id: val.organization.id,
                    address: val.address,
                });
            },
            get() {
                return this.contact;
            },
        },
        isGodUser() {
            return this.$store.getters["auth/isGodUser"];
        },
        isValidEmail() {
            if (!this.contactLocal.email) {
                return true;
            }

            return validateEmailPlt(this.contactLocal.email);
        },
    },

    methods: {
        resetErrors() {
            this.errors = [];
        },
        getPayload() {
            return {
                name: this.contactLocal.name,
                email: this.contactLocal.email,
                phone: this.contactLocal.phone,
                organization_id: this.contactLocal.organization.id,
                addresses: this.contactLocal.address?.map(address => address.id) || null,
            };
        },
        getIsValid() {
            return this.contactLocal.name && this.isValidEmail;
        },
        async updateContacts() {
            this.resetErrors();

            if (!this.getIsValid()) {
                this.$snotify.error('Fill in the required fields correctly');

                return;
            }

            const payload = this.getPayload();
            const url = this.edit ? `${this.$apiUrl.addressBook.contact}/${this.contactLocal.id}` : this.$apiUrl.addressBook.contact;

            this.loading = true;

            try {
                const { data: { data } } = await axios.post(url, payload);
                
                this.$emit('hide', !this.edit ? data : null);
                this.$snotify.success('Contacts book updated');
            } catch(error) {
                if (error?.response?.data?.errors) {
                    this.errors = Object.keys(error.response.data.errors);
                }

                this.$snotify.error(requestErrors(error));
            }

            this.loading = false;
        },
        updateOrganization(value) {
            this.contactLocal = { ...this.contactLocal, organization: value, organization_id: value?.id };
        },
        async fetchAddressOptions() {
            if (!this.contact?.organization_id) {
                return;
            }

            this.addressOptionsLoading = true;

            try {
                const { data: { data } } = await axios.get(this.$apiUrl.addressBook.base + `?filter[organization_id]=${this.contact.organization_id}&pageSize=10000`);

                const uniqueAddresses = data
                    .concat(this.contactLocal.address || [])
                    .reduce((accum, current) => {
                        accum[current.id] = current;

                        return accum;
                    }, {});

                this.addressOptions = Object.values(uniqueAddresses);
            } catch (error) {
                this.$snotify.error(requestErrors(error));
            }

            this.addressOptionsLoading = false;
        },
    },
}
</script>
<style lang="scss">
.spinner-wrapper {
    display: grid;
    place-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #fff;
}
</style>
<template>
    <div class="flex flex__column flex-wrap w-full">
        <card
            v-if="
                currentStepKey === 'finalize_goods_description'
                && !isTemplate
                && additionalServices.length
            "
            title="Aditional services"
        >
            <div class="w-full">
                <additional-service
                    v-for="service in additionalServices"
                    :key="`additional-service_${service.id}`"
                    :service="service"
                    body-class="w-1/2"
                />
            </div>
        </card>
        
        <div v-if="isFinalizeGoodsDescription && weightDiff && weightDiff > 5" class="color-grey mb-4">Total weight is different from specified while price request. Actual cost of the transportation may be different from calculated</div>

        <dropdown-block
            title="Carrier & service"
            :display-dropdown="isTemplate"
            :title-hidden="!isTemplate"
            :default-show="true"
            class="w-full"
        >
            <div class="w-full mb-4">
                <carrier-block v-if="isShortFlow" @update:carrier-service="updateCarrierServiceId" @update:carrier-service-initial="carrierServiceId = $event" />
            </div>
        </dropdown-block>

        <dropdown-block 
            title="Goods description"
            :display-dropdown="isTemplate"
            :title-hidden="!isTemplate"
            :default-show="true"
        >
            <div>
                <div class="w-full" v-for="(pkg, index) in goods" :key="`line_${index}`">
                    <card class="mb-6" :title="'Line ' + (index+1)" bodyClass="flex-wrap overflow-visible">
                        <div v-if="!isFinalizeGoodsDescription" slot="tools" class="flex flex-row items-center">
                            <button v-if="goods.length > 1" title="Remove line" @click="removePkg(index)">
                                <fa :icon="['fal','trash']" class="mr-1" /> Remove line
                            </button>

                            <div v-if="goods.length > 1" class="h-6 mx-4 border-r"></div>

                            <dropdown dropdownClass="w-48">
                                <button slot="trigger">
                                    <fa :icon="['fas','ellipsis-h']" />
                                </button>
                                <ul>
                                    <li><button @click="cloneGoods(index)"><fa :icon="['fal','copy']" class="mr-2" />Duplicate line</button></li>
                                </ul>
                            </dropdown>
                        </div>

                        <div
                            class="flex flex-wrap w-full mt-4 lower-section"
                        >
                            <modal-field
                                label="Units"
                                required
                                class="mb-4 w-1/5 pr-4"
                                :class="{ empty: getIsEmptyField(pkg.units)}"
                            >
                                <div
                                    class="mt-1"
                                >
                                    <t-input
                                        type="number"
                                        v-model="pkg.units"
                                        :error="hasError(index, 'units')"
                                        autocomplete="none"
                                        :disabled="!isFormAccessable"
                                        @input="recalculate(pkg, index)"
                                    />
                                </div>
                            </modal-field>

                            <modal-field
                                v-for="key in [
                                    fields.goods_properties.weight.key,
                                    fields.goods_properties.depth.key,
                                    fields.goods_properties.width.key,
                                    fields.goods_properties.height.key, 
                                ]"
                                :key="`good_property-${key}`"
                                :label="fields.goods_properties[key].title + ` ${fields.goods_properties[key].unit}`"
                                :class="[
                                    'mb-4 ' +fields.goods_properties[key].size,
                                    { empty: getIsEmptyField(pkg[fields.goods_properties[key].key])}
                                ]"
                                :required="getIsRequiredPropertyField(key, pkg)"
                            >
                                <div
                                    class="mt-1"
                                >
                                    <t-input
                                        type="float"
                                        v-model="pkg[fields.goods_properties[key].key]"
                                        autocomplete="none"
                                        :disabled="!isFormAccessable"
                                        @input="key === 'weight' ? calculateLineWeight(pkg, index) : calculateVolume(pkg, index)"
                                    />
                                </div>
                            </modal-field>

                            <hr class="w-full mb-4" />

                            <modal-field
                                :label="fields.goods_properties.weight_line.title + ` ${fields.goods_properties.weight_line.unit}`"
                                :class="[
                                    'mb-4 ' +fields.goods_properties.weight_line.size,
                                    { empty: getIsEmptyField(pkg[fields.goods_properties.weight_line.key])}
                                ]"
                                required
                            >
                                <div
                                    class="mt-1"
                                >
                                    <t-input
                                        type="float"
                                        v-model="pkg[fields.goods_properties.weight_line.key]"
                                        autocomplete="none"
                                        :disabled="!isFormAccessable"
                                        @input="updateField(pkg, index)"
                                        @blur="validateWeightLine(pkg[fields.goods_properties.weight_line.key], index)"
                                    />
                                </div>
                            </modal-field>

                            <modal-field
                                :label="fields.goods_properties.volume_line.title + ` ${fields.goods_properties.volume_line.unit}`"
                                :class="[
                                    'mb-4 ' +fields.goods_properties.volume_line.size,
                                    { empty: getIsEmptyField(pkg[fields.goods_properties.volume_line.key])}
                                ]"
                                :required="getIsRequiredPropertyField(fields.goods_properties.volume_line.key, pkg)"
                            >
                                <div
                                    class="mt-1"
                                >
                                    <t-input
                                        type="float"
                                        v-model="pkg[fields.goods_properties.volume_line.key]"
                                        autocomplete="none"
                                    />
                                </div>
                            </modal-field>

                            <modal-field
                                :label="fields.goods_properties.pallet_spaces.title"
                                :class="'mb-4 ' +fields.goods_properties.pallet_spaces.size"
                            >
                                <div
                                    class="mt-1"
                                >
                                    <t-input
                                        type="number"
                                        v-model="pkg[fields.goods_properties.pallet_spaces.key]"
                                        :disabled="!isFormAccessable"
                                        autocomplete="none"
                                    />
                                </div>
                            </modal-field>

                            <quote-module name="non-stackable-module" :class="fields.goods_properties.non_stackable.size">
                                <modal-field
                                    :label="fields.goods_properties.non_stackable.title"
                                    :class="[
                                        'mb-4',
                                        { empty: getIsEmptyField(pkg[fields.goods_properties.non_stackable.key])}
                                    ]">
                                        <checkbox v-model="pkg[fields.goods_properties.non_stackable.key]" :checked="pkg[fields.goods_properties.non_stackable.key]" />
                                </modal-field>
                            </quote-module>

                            <quote-module name="load-meters-module" :class="fields.goods_properties.load_meters.size">
                                <modal-field
                                    :label="fields.goods_properties.load_meters.title"
                                    :class="[
                                        'mb-4',
                                        { empty: getIsEmptyField(pkg[fields.goods_properties.load_meters.key])}
                                    ]"
                                    :required="getIsRequiredPropertyField(fields.goods_properties.load_meters.key, pkg)"
                                >
                                    <div
                                        class="mt-1"
                                    >
                                        <t-input
                                            type="float"
                                            v-model="pkg[fields.goods_properties.load_meters.key]"
                                            :disabled="!isFormAccessable"
                                            autocomplete="none"
                                        />
                                    </div>
                                </modal-field>
                            </quote-module>

                            <modal-field
                                label="Goods description"
                                required
                                class="mb-4 w-2/4 pr-4"
                                :class="{ empty: getIsEmptyField(pkg.description)}"
                            >
                                <div
                                    class="mt-1"
                                >
                                    <input
                                        v-model="pkg.description"
                                        :disabled="!isFormAccessable || (index === 0 && getIsLockedField('goods'))"
                                        class="mt-1"
                                        type="text"
                                        autocomplete="none"
                                    />
                                </div>
                            </modal-field>

                            <modal-field
                                id="package-type-field"
                                label="Package type"
                                :required="isFinalizeGoodsDescription || isShortFlow"
                                class="mb-4 w-1/4 pr-4"
                                :class="{ empty: getIsEmptyField(pkg.package_type) }"
                            >
                                <div
                                    class="mt-1"
                                >
                                    <multiselect
                                        v-model="pkg.package_type"
                                        class="mt-1"
                                        :class="[hasError(index, 'package_type')]"
                                        :options="goodsTypeList"
                                        :custom-label="customLabelPackageType"
                                        :disabled="getIsLockedField('goods') || (currentStep === 1 && !isShortFlow) || !Boolean(carrierServiceId) && isShortFlow"
                                        track-by="id"
                                        label="name"
                                        @select="onPackageTypeSelect(index)"
                                    >
                                    </multiselect>
                                </div>
                            </modal-field>

                            <modal-field
                                label="Shipping mark"
                                :class="'mb-4 w-1/4 pr-4'"
                            >
                                <div
                                    class="mt-1"
                                >
                                    <input
                                        v-model="pkg.shipping_mark"
                                        :disabled="!isFormAccessable"
                                        class="mt-1"
                                        type="text"
                                        autocomplete="none"
                                    />
                                </div>
                            </modal-field>
                        </div>
                    </card>
                </div>
                <div v-if="isFormAccessable" class="flex w-full justify-center">
                    <button type="button" class="btn flex align-center" @click="addPackage()">
                        <svg-importer icon-name="icons/plus" width="20" height="20" class="mr-1" />
                        <span>
                            Add package
                        </span>
                    </button>
                </div>
            </div>
        </dropdown-block>
    </div>

</template>

<script>

import fields from "~/data/priceleadtime_fields.json";
import TInput from "~/components/TInput";
import axios from 'axios';

import CarrierBlock from './Carrier.vue'
import DropdownBlock from '~/components/DropdownBlock.vue';
import QuoteModule from '~/components/PriceLeadTime/QuoteModule.vue';
import AdditionalService from  '~/components/PriceLeadTime/AdditionalService.vue';
import Checkbox from '~/components/Checkbox.vue';

import LockedMixin from '~/pages/transport/price-leadtime/locked.mixin.js';
import IsTemplateMixin from '~/mixins/isTemplate.mixin';
import AddServicesMixin from '~/mixins/addServices.mixin.js';

const getMinVolumePkg = (pkg) => {
    if (!(pkg.depth && pkg.height && pkg.width)) {
        return pkg;
    }

    let volume = (+pkg.depth / 100) * (+pkg.width / 100) * (+pkg.height / 100);

    // pkg.units ||= 1;
    volume *= pkg.units;

    if (isNaN(volume)) {
        return pkg;
    }

    if (Number.isInteger(volume)) {
        pkg.volume_line = volume;

        return pkg;
    }

    pkg.volume_line = Math.round((volume + Number.EPSILON) * 100) / 100;

    return pkg;
}

export default {
    name: "Goods",
    mixins: [LockedMixin, AddServicesMixin, IsTemplateMixin],
    components: {TInput, CarrierBlock, DropdownBlock, QuoteModule, AdditionalService, Checkbox},

    props: {
        dutiable: {
            type: Boolean,
            default: false,
        },
        needsServiceCheck: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        carrierServiceId: 0,
        packageTypes: [
            "Pallet",
            "Box/Parcel",
            "Envelope",
            'Groupage/Mixed cargo',
            "Truck (FTL)",
            "Container"
        ],
        fields: fields,
        pallet_sizes: [
            { key: 'full_pallet', title: 'Full pallet' },
            { key: 'half_pallet', title: 'Half pallet' }
        ],
        container_sizes: [
            { key: '20ft', title: '20ft' },
            { key: '40ft', title: '40ft' },
            { key: '40ft HC', title: '40ft HC' },
        ],
        requiredFileds: [],
        prevValue: null,
    }),


    created() {
        if (this.isFinalizeGoodsDescription) {
            this.initGoodsType();
            this.prevValue = JSON.parse(JSON.stringify(this.goods));
        }
    },

    watch: {
        priceLeadTimeResult: {
            deep: true,
            immediate: true,
            handler() {
                this.initGoodsType();
            },
        },
        modulesUpdateTriggers: {
            deep: true,
            handler(value) {
                if (!value?.length) {
                    return;
                }

                this.fetchAddServicesModules();
            },
        },
    },

    computed: {
        isShortFlow() {
            return this.$store.getters['price-leadtime/isShortFlow'];
        },
        priceLeadTimeResult() {
            return this.$store.getters['price-leadtime/getPriceLeadTimeResult'];
        },
        weightDiff() {
            if (!this.prevValue) {
                return 0;
            }

            const a = this.prevValue.reduce((p,c) => {return p, c.weight }, '');
            const b = this.goods.reduce((p,c) => {return p, c.weight }, '');

            const result = 100.0 * (b - a) / a;

            if (result < 0) {
                return result * -1;
            }

            return result;
        },
        goods: {
            get() {
                return this.$store.getters[`price-leadtime/goods`].items;
            },
            set(goods) {
                // fix computed getter reactivity in ../PriceLeadtimeForm.vue
                // fix source https://stackoverflow.com/questions/68167246/why-wont-my-template-update-with-it-being-bound-to-a-computed-property
                this.$store.commit('price-leadtime/SET_GOODS_ITEMS', goods);
            },
        },
        currentStep() {
            return this.$store.getters["price-leadtime/currentStep"];
        },
        errors: {
            set(value) {
                this.$store.getters['price-leadtime/activeSteps'][this.currentStep].errors = value;
            },
            get() {
                return this.$store.getters['price-leadtime/activeSteps'][this.currentStep].errors;
            }
        },
        currentStepKey() {
            return this.steps[this.currentStep].key;
        },
        steps() {
            return this.$store.getters['price-leadtime/activeSteps'];
        },
        isFinalizeGoodsDescription() {
            return this.currentStepKey === 'finalize_goods_description';
        },
        modulesUpdateTriggers() {
            if (this.isTemplate) {
                return null;
            }

            return this.$store.getters['price-leadtime/modulesUpdateTriggers'];
        },
        goodsTypeList: {
            get() {
                return this.$store.state['price-leadtime'].goodsTypeList;
            },
            set(value) {
                this.$store.commit('price-leadtime/SET_GOODS_TYPE_LIST', value);
            },
        },
    },

    methods: {
        onPackageTypeSelect(index) {
            delete this.errors[`items.${index}.package_type`];
        },
        getIsRequiredPropertyField(key, pkg) {
            const propertyFields = this.fields.goods_properties;

            if (key === propertyFields.weight.key) {
                return !pkg[propertyFields.weight_line.key];
            }

            if (key === propertyFields.volume_line.key) {
                return ![
                    pkg[fields.goods_properties.load_meters.key],
                        [
                            pkg[fields.goods_properties.height.key],
                            pkg[fields.goods_properties.depth.key],
                            pkg[fields.goods_properties.width.key]
                        ].every(val => val),
                ].some(val => val);
            }

            if (key === propertyFields.load_meters.key) {
                return this.dutiable
                    ? false
                    : ![
                        pkg[fields.goods_properties.volume_line.key],
                        [
                            pkg[fields.goods_properties.height.key],
                            pkg[fields.goods_properties.depth.key],
                            pkg[fields.goods_properties.width.key]
                        ].every(val => val),
                    ].some(val => val);
            }

            if (key !== propertyFields.weight.key) {
                return this.needsServiceCheck
                    ? true
                    : ![
                        pkg[fields.goods_properties.load_meters.key],
                        pkg[fields.goods_properties.volume_line.key]
                    ].some(val => val);
            }
        },
        updateCarrierServiceId(data) {
            this.carrierServiceId = data;
            this.goods.forEach(item => item.package_type = null);

            this.initGoodsType();
        },
        async initGoodsType() {
            const value = this.priceLeadTimeResult?.carrier_model?.id || this.priceLeadTimeResult?.carrier_service_model?.carrier_id; 

            if (!value) {
                return;
            }

            const query = `?filter[carrier_id]=${value}`;
            const result = await axios.get(`/api/good-type-dictionary${query}`)

            this.goodsTypeList = result?.data?.data?.filter(item => item.name) || [];

            if (!this.goodsTypeList.length) {
                this.$snotify.success('No package type found for selected carrier. Please choose another one.');
            }
        },
        customLabelPackageType({ name, code }) {
            return `${name || ''} ${code}`;
        },
        hasError(index, key) {
            return this.errors["items." + index + "." + key];
        },
        cloneGoods(index) {
            this.goods.push(Object.assign({}, this.goods[index]));
        },
        setPalletSize(value, index) {
            this.goods[index].pallet_size = value;
            this.$forceUpdate();
        },
        setContainerSize(value, index) {
            this.goods[index].container_size = value;
            this.$forceUpdate();
        },
        resetExtraFields(index) {
            this.goods[index].pallet_size = null;
            this.goods[index].container_size = null;
        },
        addPackage(){
            this.goods.push({
                goods_type: null,
                weight: null,
                load_meters: null,
                non_stackable: false,
                pallet_spaces: null,
                depth: null,
                width: null,
                height: null,
                volume: null,
                units: 1
            })
        },

        removePkg(index) {
            this.goods.splice(index, 1);
        },
        validateWeightLine(currentWeight, index) {
            const pkg = this.goods[index];
            const minWeight = +pkg.units * +pkg.weight;
            
            if (minWeight > +currentWeight) {
                pkg.weight_line = minWeight
                this.goods.splice(index, 1, pkg)
            }
        },
        validateVolumeLine(currentVolume, index) {
            const minVolumePkg = getMinVolumePkg(this.goods[index]);
            
            if (minVolumePkg.volume_line > +currentVolume) {
                this.goods.splice(index, 1, minVolumePkg);
            }
        },
        updateField(pkg, index) {
            this.goods.splice(index, 1, pkg)
        },
        recalculate(pkg, index) {
            this.calculateLineWeight(pkg, index);
            this.calculateVolume(pkg, index);
        },
        calculateLineWeight(pkg, index) {
            // pkg.units ||= 1;

            pkg.weight_line = pkg.weight ? pkg.weight * pkg.units : pkg.weight_line;

            this.goods.splice(index, 1, pkg);
        },
        calculateVolume(pkg, index){
            this.goods.splice(index, 1, getMinVolumePkg(pkg));
        },
    },
}
</script>

<style>

</style>

<template>
    <div v-if="modulesVisibility[name]">
        <slot />
    </div>
</template>
<script>
export default {
    props: {
        name: String,
    },
    computed: {
        modulesVisibility() {
            return this.$store.getters['price-leadtime/modulesVisibility'];
        },
    },
}
</script>